<template>
  <div class="wf__about">
    <router-view></router-view>
  </div>
</template>

<style scoped>
.wf__about {
  width: 90%;
  margin: 0 auto;
}

.wf__about:deep(p) {
  font-size: 16px;
}

.wf__about:deep(.toastui-editor-contents h1) {
  font-size: 40px;
  line-height: 40px;
}
</style>
